//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import About from "./Component/Tab/About/Default";
//Install
import AIM from "./Component/Tab/AIM/Default";
//Install
import Client from "./Component/Tab/Client/Default";
//Install
import Services from "./Component/Tab/Services/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/about-us" element={ <About /> }></Route>
          <Route path="/aim" element={ <AIM /> }></Route>
          <Route path="/our-clients" element={ <Client /> }></Route>
          <Route path="/services" element={ <Services /> }></Route>
          <Route path="/contact" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
