//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-2 vz893z" },
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                    //Element
                    "Home"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/about-us" },
                    //Element
                    "About Us"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/aim" },
                    //Element
                    "AIM"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/our-clients" },
                    //Element
                    "Our Clients"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/services" },
                    //Element
                    "Services"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/contact" },
                    //Element
                    "Contact Us"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;