//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import AIM from "./Content/AIM";
//Install
import Blog from "./Content/Blog";
//Install
import Brand from "./Content/Brand";
//Install
import About from "./Content/About";
//Install
import Button from "./Content/Button";
//Install
import Service from "./Content/Service";
//Install
import Services from "./Content/Services";
//Install
import { ReactComponent as B463CN } from "../Media/Icon/004.svg";
//Install
import { ReactComponent as Y76S2K } from "../Media/Icon/005.svg";
//Install
import Q9DG42 from "../Media/Video/002.mp4";

//Element
class Main extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Cover-Studio"
        //Element
        T00A3U.content = "Showcase your work at the cover of the market."
        //Element
        HU221M.content = "https://cover-studio.com/favicon.svg"
        //Element
        V337GE.content = "https://cover-studio.com/"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Cover-Studio"
        //Element
        D68001.content = "Showcase your work at the cover of the market."
        //Element
        N94MJ9.content = "https://cover-studio.com/favicon.svg"
        //Element
        S577FO.content = "https://cover-studio.com/"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Cover-Studio"
        //Element
        W2LB44.content = "Showcase your work at the cover of the market."
        //Element
        K08577.content = "https://cover-studio.com/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Cover-Studio"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Showcase your work at the cover of the market."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://cover-studio.com/" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const References = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "6",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_D19GM2",
                //Element
                prevEl      : "#Web_Z188E9"
            },
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 6
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 s288ei" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o747gy" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s40258" },
                    //Element
                    React.createElement( "video", { className: "float-start w-100 h-auto", src: Q9DG42, playsInline: true, controls: false, loop: true, autoPlay: true, muted: false } ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 uf9065" },
                        //Element
                        React.createElement( Container, {},
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:12 },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-0 m-0 text-center c137ai" },
                                        //Element
                                        "‘Showcase your work at the cover of the market’"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { id: "Content_About", className: "float-start w-100 p-0 m-0 na820w" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none eg5468" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 e2251v" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( About, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i84t5x" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 z1520t" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 text-center q5s25u" },
                                    //Element
                                    "Our Clients"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 z1520t" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...References },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fendi.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fauchon.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "chopard-logo.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "GILAN2.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "pamellata.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "Kiehls-1851-Logo_Black1.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fendi.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "fauchon.jpg", Target: "/" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Brand, { ID: "0", Title: "", Image: "chopard-logo.jpg", Target: "/" } )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 position-absolute start-0 top-0 gx42v3" },
                                    //Element
                                    React.createElement( "button", { id: "Web_Z188E9", name: "Web_Z188E9", className: "d-flex w-100 justify-content-center align-items-center position-relative rounded-circle ty74f3", type: "button" },
                                        //Element
                                        React.createElement( Y76S2K, {} )
                                    ),
                                    //Element
                                    React.createElement( "button", { id: "Web_D19GM2", name: "Web_D19GM2", className: "d-flex w-100 justify-content-center align-items-center position-relative rounded-circle x781vv", type: "button" },
                                        //Element
                                        React.createElement( B463CN, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 oj1a97" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( AIM, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-between align-items-center p-0 m-0 d-none g3285g" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 mt-4 vv75g0" },
                                    //Element
                                    React.createElement( "img", { alt: "AIM", title: "AIM", src: "https://images.pexels.com/photos/3194518/pexels-photo-3194518.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: "261", height: "auto" } )
                                ),
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 mt-4 f4ab27" },
                                    //Element
                                    React.createElement( "img", { alt: "AIM", title: "AIM", src: "https://images.pexels.com/photos/6335/man-coffee-cup-pen.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: "261", height: "auto" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o711dy" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 oq5t79" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative q843vr" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 a6ft70" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex flex-column justify-content-start align-items-start p-0 m-0 mt-5 u2k9z3" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 x11q7x" },
                                    //Element
                                    "Services"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "Cover Studio "
                                    ),
                                    //Element
                                    "is luxury creative agency that provides a range of services to help ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "luxury brands "
                                    ),
                                    //Element
                                    "with their creative and marketing needs. The services of the agency : "
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "1", Title: "Branding and Identity", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "2", Title: "Graphic Design", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "3", Title: "Web Design and Development", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "4", Title: "Social Media Management", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "5", Title: "Influencer Marketing", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "6", Title: "Content Creation", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "7", Title: "Advertising, Media Buying and Marketing Campaigns", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "8", Title: "Photography and Videography", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "9", Title: "Copywriting", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "10", Title: "SEO and Digital Marketing", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "11", Title: "Event Planning and Management", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Button, { ID: "12", Title: "Brand Monitoring and Analysis", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 t9237r" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 x195er" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Service, { ID: "1", Title: "Branding and Identity", Summary: "", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "2", Title: "Graphic Design", Summary: "Creating visually appealing designs for various purposes, such as brochures, posters, packaging, business cards, invitations, and digital graphics.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "3", Title: "Web Design and Development", Summary: "Designing and building websites that are visually appealing, user-friendly, and optimized for search engines.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "4", Title: "Social Media Management", Summary: "Developing and implementing social media strategies, creating engaging content, and managing social media accounts.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "5", Title: "Influencer Marketing", Summary: "Building a relevant social community, engaging creatives and talents with luxury brands through influencer marketing, implementation and execution of the developed projects.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "6", Title: "Content Creation", Summary: "Producing high-quality content for various platforms, including blog posts, articles, videos, and infographics.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "7", Title: "Advertising and Marketing", Summary: "Planning and executing creative advertising and marketing campaign across different channels ; print, digital, and social media.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "8", Title: "Photography and Videography", Summary: "Capturing professional photos and videos for promotional purposes, such as product photography, corporate videos, and event coverage.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "9", Title: "Copywriting", Summary: "Crafting compelling and persuasive copy for websites, advertisements, and other marketing materials.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "10", Title: "SEO and Digital Marketing", Summary: "Optimizing websites for search engines, conducting keyword research, and implementing digital marketing strategies to increase online visibility and drive traffic.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "11", Title: "Event Planning and Management", Summary: "Organizing and managing corporate events, product launches, and promotional activities.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "12", Title: "Brand Analysis", Summary: "Monitoring the brand's reputation and performance, analyzing market trends, and making data-driven decisions to continuously improve the brand strategy.", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 o2gk67" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s7d04i" },
                                //Element
                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 nk96w3", placeholder: "Your Name", type: "text" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s7d04i" },
                                //Element
                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 nk96w3", placeholder: "E-mail", type: "text" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s7d04i" },
                                //Element
                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 nk96w3", placeholder: "Telephone", type: "text" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s7d04i" },
                                //Element
                                React.createElement( "button", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 e74861", type: "button" },
                                    //Element
                                    "Book a Call"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;