//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Button extends Component {
    //Element
    T294H2 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Y2Y361 = document.getElementsByClassName( "m4q81o" )
        //Element
        for (let Index = 0; Index < Y2Y361.length; Index++) {
            //Element
            Y2Y361[Index].classList.add( "opacity-50" )
        }
        //Element
        if( e.relatedTarget.childNodes[0] != null ){
            //Element
            e.relatedTarget.childNodes[0].classList.remove( "opacity-50" )
        }
        //Element
        e.nativeEvent.target.classList.add( "bg-black" )
    }
    //Element
    DK4O00 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Y2Y361 = document.getElementsByClassName( "m4q81o" )
        //Element
        for (let Index = 0; Index < Y2Y361.length; Index++) {
            //Element
            Y2Y361[Index].classList.remove( "opacity-50" )
        }
        //Element
        e.nativeEvent.target.classList.remove( "bg-black" )
    }
    //Element
    T9505P = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { ID } = this.props
        //Element
        window.location.hash = "Web_Services_" + ID
    }
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 q3412g" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 rounded-circle m4q81o", type: "button", onMouseOver: this.T294H2, onMouseOut: this.DK4O00, onClick: this.T9505P },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b4e26g" },
                    //Element
                    ID
                )
            ),
            //Element
            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center kv554t" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Button.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Button;