//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate a93n9n" },
                        //Element
                        "Company"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 opq677" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/about-us" },
                                //Element
                                "About"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/contact" },
                                //Element
                                "Career"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 a93n9n" },
                        //Element
                        "Services"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 opq677" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Branding and Identity"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Graphic Design"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Web Design and Development"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Social Media Management"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 opacity-0 a93n9n" },
                        //Element
                        "Services"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 opq677" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Influencer Marketing"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Content Creation"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Advertising and Marketing"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Photography and Videography"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 opacity-0 a93n9n" },
                        //Element
                        "Services"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 opq677" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Copywriting"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "SEO and Digital Marketing"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Event Planning and Management"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/services" },
                                //Element
                                "Brand Analysis"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 a93n9n" },
                        //Element
                        "Contact"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 opq677" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/contact" },
                                //Element
                                "Call"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/contact" },
                                //Element
                                "E-mail"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 text-truncate", href: "/contact" },
                                //Element
                                "Locations"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;