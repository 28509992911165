//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Q9DG42 from "../../../Media/Video/002.mp4";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Out Clients | Cover-Studio"
        //Element
        T00A3U.content = "Showcase your work at the cover of the market."
        //Element
        HU221M.content = "https://cover-studio.com/favicon.svg"
        //Element
        V337GE.content = "https://cover-studio.com/our-clients"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Out Clients | Cover-Studio"
        //Element
        D68001.content = "Showcase your work at the cover of the market."
        //Element
        N94MJ9.content = "https://cover-studio.com/favicon.svg"
        //Element
        S577FO.content = "https://cover-studio.com/our-clients"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Out Clients | Cover-Studio"
        //Element
        W2LB44.content = "Showcase your work at the cover of the market."
        //Element
        K08577.content = "https://cover-studio.com/favicon.svg"
    }
    //Element
    T6Q936 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let D74C4O = document.getElementsByClassName( "r3q378" )
        //Element
        for (let Index = 0; Index < D74C4O.length; Index++) {
            //Element
            D74C4O[Index].classList.add( "opacity-50" )
        }
        //Element
        e.target.classList.remove( "opacity-50" )
        //Element
        e.target.classList.add( "bg-black" )
    }
    //Element
    F5190O = (e) => {
        //Element
        e.preventDefault()
        //Element
        let D74C4O = document.getElementsByClassName( "r3q378" )
        //Element
        for (let Index = 0; Index < D74C4O.length; Index++) {
            //Element
            D74C4O[Index].classList.remove( "opacity-50" )
        }        
        //Element
        e.target.classList.remove( "bg-black" )
    }
    //Element
    HY376T = (e) => {
        //Element
        e.preventDefault()
        //Element
        let W6N95P = ""
        //Element
        let FF0887 = "Group_"
        //Element
        let D74C4O = document.getElementsByClassName( "r3q378" )
        //Element
        for (let Index = 0; Index < D74C4O.length; Index++) {
            //Element
            D74C4O[Index].classList.add( "opacity-50" )
            //Element
            D74C4O[Index].classList.add( "u0Bc59" )
            //Element
            D74C4O[Index].classList.remove( "bg-black" )
        }
        //Element
        let T9TC87 = document.getElementsByClassName( "x70372" )
        //Element
        let VV7A01 = document.querySelectorAll( "#Group_All .ew6559" )
        //Element
        for (let Index = 0; Index < T9TC87.length; Index++) {
            //Element
            T9TC87[Index].classList.add( "d-none" )
        }
        //Element
        for (let Index = 0; Index < VV7A01.length; Index++) {
            //Element
            VV7A01[Index].classList.add( "opacity-25" )
        }
        //Element
        e.target.classList.remove( "opacity-50" )
        //Element
        e.target.classList.remove( "u0Bc59" )
        //Element
        e.target.classList.add( "bg-black" )
        //Element
        W6N95P = FF0887 + e.nativeEvent.target.id
        //Element
        document.getElementById( W6N95P ).classList.remove( "d-none" )
    }
    //Element
    QV929J = () => {
        //Element
        let T9TC87 = document.getElementsByClassName( "x70372" )
        //Element
        let VV7A01 = document.querySelectorAll( "#Group_All .ew6559" )
        //Element
        for (let Index = 0; Index < T9TC87.length; Index++) {
            //Element
            T9TC87[Index].classList.add( "d-none" )
        }
        //Element
        for (let Index = 0; Index < VV7A01.length; Index++) {
            //Element
            VV7A01[Index].classList.add( "opacity-25" )
        }
        //Element
        document.getElementsByClassName( "x70372" )[0].classList.remove( "d-none" )
    }
    //Element
    Z48J1W = (e) => {
        //Element
        let X7DH59 = document.querySelectorAll( "#Group_All .ew6559" )
        //Element
        for (let Index = 0; Index < X7DH59.length; Index++) {
            //Element
            X7DH59[Index].classList.add( "opacity-25" )
        }
        e.target.classList.remove( "opacity-25" )
    }
    //Element
    W023X3 = (e) => {
        //Element
        let X7DH59 = document.querySelectorAll( "#Group_All .ew6559" )
        //Element
        for (let Index = 0; Index < X7DH59.length; Index++) {
            //Element
            X7DH59[Index].classList.add( "opacity-25" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Out Clients | Cover-Studio"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Showcase your work at the cover of the market."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://cover-studio.com/our-clients" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.QV929J()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 s288ei" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 q50uy5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 v9n504" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md: "1 offset-md-2" },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "All", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "ALL"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "A", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "A"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "B", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "B"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "C", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "C"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "D", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "D"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "E", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "E"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "F", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "F"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "G", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "G"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md: "1 offset-md-2" },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "H", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "H"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "I", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "I"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "J", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "J"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "K", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "K"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "L", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "L"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "M", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "M"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "N", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "N"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "O", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "O"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md: "1 offset-md-2" },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "P", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "P"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "Q", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "Q"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "R", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "R"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "S", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "S"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "T", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "T"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "U", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "U"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "V", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "V"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "W", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "W"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md: "1 offset-md-2" },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "X", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "X"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "Y", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "Y"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:1 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 o344kj" },
                                            //Element
                                            React.createElement( "span", { id: "Z", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle r3q378", onClick: this.HY376T },
                                                //Element
                                                "Z"
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md: "8 offset-md-2" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 zz447c" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 mb-4 j693mc" } ),
                                //Element
                                React.createElement( "div", { id: "Group_All", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Azimut_Yachts_b.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Audemars_Piguet.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ARGOS-IN-CAPPADOCIA.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ARMANI-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ATELIER-REBUL.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/biennale.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/BUDDHA-BAR-BEACH-BODRUM.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/st-regis-istanbul.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/borusanotomotiv2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/bulgari.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/boatshow.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/brandroom.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/camicissima.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/chopard-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Calzedonia.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Caresse.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Demsa_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/DOUGLAS-ELLIMAN.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/topshop-topman.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/22.png", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ECOLE_ST_PIERRE_LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Endeavor_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/falconeri.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/fendi.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/fauchon.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/FAIRLINE.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/omega.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GL_LOGO2L_N.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/geyre.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GILAN2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GIANVITO-ROSSI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GATE27.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GIORGIO-ARMANI-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/hnlogo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/logo-hogan.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/INTIMISSIMI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/INTIMISSIMI-UOMO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jaguar-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jimmy.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jowae.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/JOALI-LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Kiehls-1851-Logo_Black1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lpm_logo__1_2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/NGN1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/LANCOME.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lierac.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lesbenjamins.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/luisaviaroma.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/LORO-PIANA.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/majesty_yacht.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Bodrum-Blue1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/NOVIKOV.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/panerai-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Patek_Philippe_Geneve_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/pamellata.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/phyto1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/prada.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/PI_LEVNELI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/reges.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/The_Peninsula_Hotels_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/samsung.png", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Sunset.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/rrr.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/st-regis.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/SHA_LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/SS_Kocatas_standard_color_logosml.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/six_senses_kaplankaya.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Tudor.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/unnamed.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/quasar.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/yali-kavak.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/YSL-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Yves-Rocher.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559", onMouseOver: this.Z48J1W, onMouseOut: this.W023X3 },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/zuma-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_A", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Azimut_Yachts_b.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Audemars_Piguet.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ARGOS-IN-CAPPADOCIA.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ARMANI-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ATELIER-REBUL.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_B", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/biennale.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/BUDDHA-BAR-BEACH-BODRUM.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/borusanotomotiv2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/bulgari.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/boatshow.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/brandroom.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_C", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/camicissima.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/chopard-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Calzedonia.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Caresse.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_D", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Demsa_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/DOUGLAS-ELLIMAN.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_E", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/topshop-topman.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/22.png", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/ECOLE_ST_PIERRE_LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Endeavor_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_F", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/falconeri.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/fendi.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/fauchon.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/FAIRLINE.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_G", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GL_LOGO2L_N.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/geyre.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GILAN2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GIANVITO-ROSSI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GATE27.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/GIORGIO-ARMANI-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_H", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/hnlogo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/logo-hogan.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_I", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/INTIMISSIMI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/INTIMISSIMI-UOMO.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_J", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jaguar-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jimmy.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/jowae.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/JOALI-LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_K", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Kiehls-1851-Logo_Black1.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_L", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lpm_logo__1_2.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/NGN1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/LANCOME.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lierac.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/lesbenjamins.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/luisaviaroma.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/LORO-PIANA.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_M", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/majesty_yacht.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Bodrum-Blue1.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_N", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/NOVIKOV.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_O", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_P", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/panerai-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Patek_Philippe_Geneve_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/pamellata.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/phyto1.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/prada.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/PI_LEVNELI.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/The_Peninsula_Hotels_logo.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_Q", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/quasar.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_R", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/reges.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_S", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/samsung.png", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Sunset.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/rrr.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/st-regis.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/SHA_LOGO.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/SS_Kocatas_standard_color_logosml.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/six_senses_kaplankaya.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_T", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Tudor.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/unnamed.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_U", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_V", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_W", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_X", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {} )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_Y", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/yali-kavak.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/YSL-BEAUTY.jpg", width: "122", height: "auto" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/Yves-Rocher.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { id: "Group_Z", className: "float-start w-100 p-0 m-0 d-none x70372" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:3 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 ps-2 pe-2 m-0 mb-2 ew6559" },
                                                //Element
                                                React.createElement( "img", { alt: "", title: "", src: "https://priveepr.com/assets/theme-file/img/logos3/zuma-logo.jpg", width: "122", height: "auto" } )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;