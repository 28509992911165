//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Brand from "./Footer/Brand";
//Install
import Social from "./Footer/Social";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Newsletter from "./Footer/Newsletter";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 g69652" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w6v717" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z8j92s" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    //React.createElement( Col, { md:6 },
                                        //Element
                                        //React.createElement( Brand, {} )
                                    //),
                                    //Element
                                    React.createElement( Col, { md:12 },
                                        //Element
                                        React.createElement( Social, { ID: "0", Facebook: "/", X: "/", Instagram: "/", Youtube: "/", Linkedin: "/" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-4 pb-4 m-0 v80y78" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            //React.createElement( Newsletter, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 azw915" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 a3r27y" },
                                //Element
                                React.createElement( Copyright, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;