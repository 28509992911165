//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    R2U07I = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.hash = "#Content_About"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 i1i29r" },
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase", title: "Home", href: "/" },
                    //Element
                    "Home"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase", title: "About Us", href: "/about-us", onClick: this.R2U07I },
                    //Element
                    "About Us"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase", title: "AIM", href: "/aim" },
                    //Element
                    "AIM"
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase", title: "Our Clients", href: "/our-clients" },
                    //Element
                    "Our Clients"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative text-uppercase", title: "Services", href: "/services" },
                    //Element
                    "Services"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;